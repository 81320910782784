import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"
const UserProfile = lazy(() => import("pages/UserProfile"))
const Security = lazy(() => import("pages/UserProfile/Security"))
const PersonalUsageHistory = lazy(() => import("pages/UserProfile/PersonalUsageHistory"))
const Notification = lazy(() => import("pages/Notification"))
const DetailNotification = lazy(() => import("pages/Notification/DetailNotification"))
const Service = lazy(() => import("pages/Service"))
const UpdateService = lazy(() => import("pages/Service/UpdateService"))
const FunctionSetting = lazy(() => import("pages/FunctionSetting"))

const UpdateFunctionSetting = lazy(() => import("pages/FunctionSetting/UpdateFunctionSetting"))
const ApprovalSetting = lazy(() => import("pages/ApprovalSetting"))
const UpdateApproval = lazy(() => import("pages/ApprovalSetting/UpdateApproval"))
const ApprovalList = lazy(() => import("pages/ApprovalList"))
const Dashboard = lazy(() => import("pages/Dashboard"))
const ActivityLogs = lazy(() => import("pages/ActivityLogs"))
const ViewLog = lazy(() => import("pages/ActivityLogs/ViewLog"))
const VehicleManagement = lazy(() => import("pages/VehicleManagement"))
const VehicleSetting = lazy(() => import("pages/VehicleSetting"))
const UpdateVehicleSetting = lazy(() => import("pages/VehicleSetting/UpdateVehicleSetting"))
const UpdateVehicleManagement = lazy(
  () => import("pages/VehicleManagement/UpdateVehicleManagement")
)
const SendDeviceManagement = lazy(() => import("pages/SendDeviceManagement"))
const UpdateSendDevice = lazy(() => import("pages/SendDeviceManagement/UpdateSendDevice"))
const ReceiveDeviceManagement = lazy(() => import("pages/ReceiveDeviceManagement"))
const UpdateReceiveDevice = lazy(() => import("pages/ReceiveDeviceManagement/UpdateSendDevice"))
const SimDeviceManagement = lazy(() => import("pages/SimDeviceManagement"))
const UpdateSim = lazy(() => import("pages/SimDeviceManagement/UpdateSim"))
const TelecomCompany = lazy(() => import("pages/TelecomCompany"))
const UpdateTelecomCompany = lazy(() => import("pages/TelecomCompany/UpdateTelecomCompany"))
const OperationLog = lazy(() => import("pages/OperationLog"))
const ExportsManage = lazy(() => import("pages/ExportsManage"))
const Reminder = lazy(() => import("pages/Reminder"))
const ListProject = lazy(() => import("pages/ListProject"))

const CompanySetting = lazy(() => import("pages/CompanySetting"))
const UpdateLocalization = lazy(() => import("pages/CompanySetting/UpdateLocalization"))

const SendNotification = lazy(() => import("pages/SendNotification"))
const UpdateEndUserNotification = lazy(
  () => import("pages/SendNotification/UpdateEndUserNotification")
)
const SegmentNotification = lazy(() => import("pages/SegmentNotification"))
const UpdateSegmentNotification = lazy(
  () => import("pages/SegmentNotification/UpdateSegmentNotification")
)

//____ListPage
function pageList(__role) {
  return [
    {
      Element: UserProfile,
      path: "/profile",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: Security,
      path: "/profile/security",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: PersonalUsageHistory,
      path: "/profile/personal_data_history",
      code: "PROFILE_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: Notification,
      path: "/notification",
      code: "NOTIFICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      Element: DetailNotification,
      path: "/notification/:id",
      code: "NOTIFICATION_CONTROLLER",
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "service_management",
      Element: Service,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "service_management/action",
      Element: UpdateService,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "functions_settings",
      pageName: "List Organization",
      Element: FunctionSetting,
      resource: RESOURCE_KEY.ROOT_FUNCTION_SETTING
    },
    {
      path: "functions_settings/service/:organization_id",
      Element: UpdateFunctionSetting,
      resource: RESOURCE_KEY.ROOT_FUNCTION_SETTING
    },
    {
      path: "approval/list_approval",
      Element: ApprovalList,
      resource: RESOURCE_KEY.ROOT_APPROVAL
    },
    {
      path: "approval/approval_setting/action",
      Element: UpdateApproval,
      resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    },

    {
      path: "approval/approval_setting",
      pageId: "abc",
      Element: ApprovalSetting,
      resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    },
    // {
    //   path: "master_data_management/wrapper_api",
    //   Element: WrapperMasterList,
    //   resource: "WRAPPERLIST_CONTROLLER"
    // },
    // {
    //   path: "master_data_management/wrapper_api/:wrapper_id",
    //   Element: CreateWrapper,
    //   resource: "WRAPPERLIST_CONTROLLER"
    // },
    // {
    //   path: "master_data_management/wrapper_api/view/:wrapper_id",
    //   Element: DetailWapper,
    //   resource: "WRAPPERLIST_CONTROLLER"
    // },
    // {
    //   path: "master_data_management/wapper_api_group",
    //   Element: WrapperMasterGroup,
    //   resource: "WRAPPERGROUP_CONTROLLER"
    // },

    {
      path: "device_management/send_device",
      Element: SendDeviceManagement,
      resource: RESOURCE_KEY.ROOT_DEVICE
    },
    {
      path: "device_management/send_device/action",
      Element: UpdateSendDevice,
      resource: RESOURCE_KEY.ROOT_DEVICE
    },
    {
      path: "device_management/receive_device",
      Element: ReceiveDeviceManagement,
      resource: RESOURCE_KEY.ROOT_DEVICE
    },
    {
      path: "device_management/receive_device/action",
      Element: UpdateReceiveDevice,
      resource: RESOURCE_KEY.ROOT_DEVICE
    },
    {
      path: "device_management/sim",
      Element: SimDeviceManagement,
      resource: RESOURCE_KEY.ROOT_SIM
    },
    {
      path: "device_management/sim/action",
      Element: UpdateSim,
      resource: RESOURCE_KEY.ROOT_SIM
    },
    {
      path: "/device_management/telecom-company",
      Element: TelecomCompany,
      resource: RESOURCE_KEY.ROOT_TELECOM
    },
    {
      path: "/device_management/telecom-company/action",
      Element: UpdateTelecomCompany,
      resource: RESOURCE_KEY.ROOT_TELECOM
    },
    {
      path: "/device_management",
      Element: OperationLog,
      resource: RESOURCE_KEY.ROOT_DEVICE
    },
    {
      path: "/exports",
      Element: ExportsManage,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "projects",
      Element: ListProject,
      resource: RESOURCE_KEY.ROOT_PROJECT_RESOURCE
    },

    // {
    //   path: "approval/approval_setting/action",
    //   Element: UpdateApproval,
    //   resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    // },
    //
    // {
    //   path: "approval/approval_setting",
    //   pageId: "abc",
    //   Element: ApprovalSetting,
    //   resource: RESOURCE_KEY.ROOT_APPROVAL_SETTING
    // },
    {
      path: "activity_logs",
      Element: ActivityLogs,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },
    {
      path: "activity_logs/:log_id",
      Element: ViewLog,
      resource: RESOURCE_KEY.ROOT_BYPASS
    },

    {
      path: "/vehicle/management",
      Element: VehicleManagement,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      path: "vehicle/management/action",
      Element: UpdateVehicleManagement,
      resource: RESOURCE_KEY.ROOT_VEHICLE
    },
    {
      path: "/vehicle/setting",
      Element: VehicleSetting,
      resource: RESOURCE_KEY.ROOT_VEHICLE_SETTING
    },
    {
      path: "/vehicle/setting/action",
      Element: UpdateVehicleSetting,
      resource: RESOURCE_KEY.ROOT_VEHICLE_SETTING
    },
    {
      path: "/vehicle/reminder",
      Element: Reminder,
      resource: RESOURCE_KEY.ROOT_REMINDER
    },
    {
      path: "/company-setting",
      Element: CompanySetting,
      resource: RESOURCE_KEY.ROOT_AC_ORGANIZATION
    },
    {
      path: "/company-setting/localization",
      Element: UpdateLocalization,
      resource: RESOURCE_KEY.ROOT_AC_ORGANIZATION
    },
    {
      path: "/notification/send-notification",
      Element: SendNotification,
      resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    },
    {
      path: "/notification/send-notification/action",
      Element: UpdateEndUserNotification,
      resource: RESOURCE_KEY.ROOT_SEND_NOTIFICATION
    },
    {
      path: "/notification/segment",
      Element: SegmentNotification,
      resource: RESOURCE_KEY.ROOT_SEGMENT
    },
    {
      path: "/notification/segment/action",
      Element: UpdateSegmentNotification,
      resource: RESOURCE_KEY.ROOT_SEGMENT
    },
    {
      path: "/",
      Element: Dashboard,
      resource: RESOURCE_KEY.ROOT_SERVICE
    },
    {
      path: "dashboard",
      pageName: "Dashboard",
      Element: Dashboard,
      resource: RESOURCE_KEY.ROOT_SERVICE
    } //next_component
  ]
}

export default pageList
