export const RESOURCE_KEY = {
  ROOT_GW_ORGANIZATION: "root_gw_organization",
  ROOT_WRAPPER_MASTER_DATA: "root_wrapper_master_data",
  ROOT_WRAPPER: "root_wrapper",
  ROOT_WRAPPER_GROUP_MASTER_DATA: "root_wrapper_group_master_data",
  ROOT_WRAPPER_GROUP: "root_wrapper_group",
  ROOT_ALERT_RULE_MASTER_DATA: "root_alert_rule_master_data",
  ROOT_ALERT_RULE: "root_alert_rule",
  ROOT_USER: "root_user",
  ROOT_IDM_ORGANIZATION: "root_idm_organization",
  ROOT_POLICY: "root_policy",
  ROOT_ROLE: "root_role",
  ROOT_IDENTITY_PROVIDER: "root_identity_provider",
  ROOT_OAUTH_CLIENT: "root_oauth_client",
  ROOT_APPLICATION: "root_idm_application",
  ROOT_AC_ORGANIZATION: "root_ac_organization",
  ROOT_VEHICLE: "root_vehicle",
  ROOT_VEHICLE_SETTING: "root_vehicle_setting",
  ROOT_REMINDER: "root_reminder",
  ROOT_RESOURCE: "root_resource",
  ROOT_DESIGN_FILE: "root_design_file",
  ROOT_TRANSLATION_VERSION: "root_translation_version",
  ROOT_UI_MANAGEMENT: "root_ui_management",
  ROOT_TRANSLATION_TASK: "root_translation_task",
  ROOT_TRANSLATION_ELEMENT: "root_translation_element",
  ROOT_CLIENT_DICTIONARY: "root_client_dictionary",
  ROOT_SYSTEM_ERROR: "root_system_error",
  ROOT_ALL_RESOURCES: "root_all_resources",
  ROOT_PROJECT_RESOURCE: "root_project_resource",
  ROOT_SERVICE: "root_service",
  ROOT_APPROVAL_SETTING: "root_approval_setting",
  ROOT_APPROVAL: "root_approval",
  ROOT_FUNCTION_SETTING: "root_idm_func_setting",
  SEND_DEVICE: "root_send_device",
  ROOT_DASHBOARD: "root_dashboard_idm",
  ROOT_CLIENT_REPORT: "root_ac_report",
  ROOT_BYPASS: "root_by_pass",
  ROOT_DEVICE: "root_device",
  ROOT_TELECOM: "root_telecom_vendor",
  ROOT_SIM: "root_sim",
  ROOT_SEND_NOTIFICATION: "root_send_notification",
  ROOT_SEGMENT: "root_segment",
  ROOT_AC_VEHICLE_UPDATE_STATUS: "root_ac_staff_vehicle.ac_staff_vehicle_path_update_status"
}

export const SERVICE_CODE = {
  API_CONTROL_PLANE: "api-control-plane",
  API_ASSET_CONTROL: "api-asset-control",
  API_CHAUFFEUR: "	api-chauffeur-connect",
  API_OM_API_GATEWAY: "api-om-api-gateway",
  API_IDM: "api-idm",
  API_AMS: "api-ams",
  API_COMMOM: "api-common",
  API_NOTI_INAPP: "api-notification-inapp"
}

export const ENVIRONMENT = [
  {
    label: "Development",
    value: "development"
  },
  {
    label: "Testing",
    value: "testing"
  },
  {
    label: "Staging",
    value: "staging"
  },
  {
    label: "Production",
    value: "production"
  }
]

export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const PLATFORM = [
  {
    label: "Web",
    value: "web"
  },
  {
    label: "Mobile",
    value: "mobile"
  }
]

export const ACTION_LOGS = [
  {
    label: "2009_4044",
    value: "create"
  },
  {
    label: "2009_4045",
    value: "update"
  },
  {
    label: "2009_4046",
    value: "delete"
  }
]

export const TYPE_BLOCK_NOTI = {
  APPROVAL: 1,
  NEWS: 2,
  MARKETING: 3,
  PROMOTION: 4,
  EVENT: 5,
  TASK: 6
}

export const REMINDER_TYPES = {
  INSPECTION: 0,
  INSURANCE: 1,
  TOWING: 2
}

export const Approval_Status = {
  approved: "approved",
  rejected: "rejected",
  in_process: "in_process"
}
export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const ORGANIZATION_SETTING_TYPE = {
  adminPassword: 1,
  enduserPassword: 2,
  accessLimit: 3,
  otp: 4
}

export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}

export const OrganizationType = {
  ServiceUser: 1,
  Service_Provider_B2b: 2,
  Service_Provider_B2c: 3
}

export const SendNotificationStatus = {
  DRAFT: 1,
  PUBLISH: 2
}

export const SendNotificationType = {
  END_USER: 1,
  PORTAL_USER: 2
}

export const SendNotificationPortalType = {
  CLIENT_PORTAL: 1,
  ADMIN_PORTAL: 2
}

export const ENUMS_CONFIG = {
  method: [
    {
      label: "GET",
      value: "GET"
    },
    {
      label: "POST",
      value: "POST"
    },
    {
      label: "PUT",
      value: "PUT"
    },
    {
      label: "PATCH",
      value: "PATCH"
    },
    {
      label: "DELETE",
      value: "DELETE"
    },
    {
      label: "OPTIONS",
      value: "OPTIONS"
    }
  ],

  service_auth_methods: [
    {
      label: "API Key",
      value: "api_key"
    },
    {
      label: "Basic Auth",
      value: "basic_auth"
    },
    {
      label: "None",
      value: "none"
    }
  ],
  service_protocols: [
    {
      label: "HTTPS",
      value: "https"
    },
    {
      label: "GRPC",
      value: "grpc"
    },
    {
      label: "MQTT",
      value: "mqtt"
    },
    {
      label: "WEBSOCKET",
      value: "websocket"
    }
  ],
  approval_status: [
    {
      label: "In Process",
      value: "in_process"
    },
    {
      label: "Approved",
      value: "approved"
    },
    {
      label: "Rejected",
      value: "rejected"
    }
  ],
  add_to: [
    {
      label: "Header",
      value: "header"
    },
    {
      label: "Query Params",
      value: "query_params"
    }
  ],
  sim_status: [
    {
      label: "Inactive",
      value: "0"
    },
    {
      label: "Active",
      value: "1"
    }
  ],
  sim_connected_status: [
    {
      label: "Disconnect",
      value: "0"
    },
    {
      label: "Connecting",
      value: "1"
    },
    {
      label: "Connected",
      value: "2"
    }
  ],
  device_status: [
    {
      label: "I2067_6669;75_53940;75_53853;75_53818",
      value: "0",
      color: ""
    },
    {
      label: "I2067_6669;75_53940;75_53854;75_53818",
      value: "1",
      color: "orange"
    },
    {
      label: "I2067_6669;75_53940;75_53855;75_53818",
      value: "2",
      color: "#108ee9"
    },
    {
      label: "I2067_6669;75_53940;75_53856;75_53818",
      value: "3",
      color: "#f50"
    }
  ],
  receive_device_status: [
    {
      label: "I2067_7185;75_53940;75_53853;75_53818",
      value: 1,
      color: "orange"
    },
    {
      label: "I2067_7185;75_53940;75_53854;75_53818",
      value: 2,
      color: "#108ee9"
    },
    {
      label: "I2067_7185;75_53940;75_53855;75_53818",
      value: 3,
      color: "#f50"
    }
  ],
  vehicle_status: [
    {
      label: "In Stock",
      value: 0
    },
    {
      label: "Sent",
      value: 1
    },
    {
      label: "Delivered",
      value: 2
    },
    {
      label: "Returning",
      value: 3
    }
  ],
  organization_type: [
    {
      label: "Tenant",
      value: "1"
    },
    {
      label: "Service Provider",
      value: "2"
    },
    {
      label: "Hybrid",
      value: "3"
    }
  ],
  history_action: [
    {
      label: "Create",
      value: "0"
    },
    {
      label: "Update",
      value: "1"
    },
    {
      label: "Update_status",
      value: "2"
    }
  ]
}

export const PHONE_COUNTRY = {
  thaiLand: {
    code: "+66",
    length: 9
  },
  southAfrica: {
    code: "+27",
    length: 9
  },
  japan: {
    code: "+81",
    length: 10
  },
  poland: {
    code: "+590",
    length: 9
  }
}
